<template>
  <v-stepper v-model="step">
    <!-- Stepper Header -->
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1" :color="headerColor(step > 1)">
        {{ $t("membership-memberdata") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="step > 2" :color="headerColor(step > 2)">
        {{ $t("membership-contribution") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="submit" :color="headerColor(submit)">
        {{ $t("membership-completion") }}
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Content  -->
    <v-stepper-items>
      <!-- Step 1 -->
      <v-stepper-content step="1">
        <v-form ref="form-memberdata" v-model="validMemberdata">
          <h1 class="text-center">{{ $t("membership-memberdata") }}</h1>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="px-4">
                <h3 class="text-center mb-3">
                  {{ $t("membership-persondata") }}
                </h3>
                <v-text-field v-model="name" :rules="nameRules" :label="$t('name')" :filled="fieldsFilled"
                  required></v-text-field>
                <v-text-field v-model="lastname" :rules="lastnameRules" :label="$t('lastname')" :filled="fieldsFilled"
                  required></v-text-field>
                <!-- Date Picker Menu -->
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="birthDate" :label="$t('birthdate')" append-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on" :filled="fieldsFilled" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="birthDate" :active-picker.sync="activePicker" @input="menu = false"
                    :first-day-of-week="1" :locale="this.$i18n.locale" color="accent" :max="today" min="1900-01-01"
                    @change="saveDate"></v-date-picker>
                </v-menu>
                <v-text-field v-model="email" :rules="emailRules" :label="$t('email')" :filled="fieldsFilled"
                  required></v-text-field>
                <v-text-field v-model="phone" :rules="phoneRules" :label="$t('phone')" :hint="$t('optional')"
                  :filled="fieldsFilled" persistent-hint></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-4">
                <h3 class="text-center mb-3">
                  {{ $t("membership-addressdata") }}
                </h3>
                <v-text-field v-model="street" :rules="streetRules" :label="$t('street')" :filled="fieldsFilled"
                  required></v-text-field>
                <v-text-field v-model="suffix" :rules="suffixRules" :label="$t('suffix')" :hint="$t('optional')"
                  :filled="fieldsFilled" persistent-hint></v-text-field>
                <v-text-field v-model="postcode" :rules="postcodeRules" :label="$t('postcode')" :filled="fieldsFilled"
                  required></v-text-field>
                <v-text-field v-model="city" :rules="cityRules" :label="$t('city')" :filled="fieldsFilled"
                  required></v-text-field>
                <v-text-field v-model="country" :rules="countryRules" :label="$t('country')" :filled="fieldsFilled"
                  required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col align="center">
              <v-btn color="accent" @click="continueForm('form-memberdata')">
                {{ $t("continue") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- Step 2 -->
      <v-stepper-content step="2">
        <v-container>
          <v-form ref="form-contribution" v-model="validContribution">
            <h1 class="mb-5 text-center">
              {{ $t("membership-contribution") }}
            </h1>
            <v-row>
              <v-col cols="12" sm="6" class="px-4">
                <h3 class="text-center mb-3">{{ $t("membership") }}</h3>
                <v-select v-model="membership" :items="membershipTypes" :label="$t('membership-type')"
                  :rules="membershipRules" :filled="fieldsFilled" required></v-select>
                <div v-if="membership == $t('ordinary-membership') ||
                  membership == $t('supporting-membership')
                  ">
                  <v-row :no-gutters="this.$vuetify.breakpoint.mdAndDown">
                    <v-col cols="12" lg="6">
                      <v-text-field v-model="contribution" :label="$t('monthly-contribution')" :rules="contributionRules"
                        :filled="fieldsFilled" :hint="monthlyContributionHint" placeholder="2.50"
                        append-icon="mdi-currency-eur" required persistent-hint
                        @update:error="invalidContributionValue = $event"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field v-model="quarterlyContribution" :label="$t('quarterly-contribution')"
                        :filled="fieldsFilled" append-icon="mdi-currency-eur" :hint="$t('quarterly-hint')" disabled
                        persistent-hint></v-text-field>
                    </v-col>
                  </v-row>
                  <h3 class="text-center mt-4 mb-3">
                    {{ $t("membership-sepa") }}
                  </h3>
                  <v-text-field v-model="accountHolder" :rules="accountHolderRules" :label="$t('account-holder')"
                    :filled="fieldsFilled" required></v-text-field>
                  <v-row :no-gutters="this.$vuetify.breakpoint.mdAndDown">
                    <v-col cols="12" lg="8">
                      <v-text-field v-model="iban" :rules="ibanRules" :label="$t('iban')" :filled="fieldsFilled"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field v-model="bic" :rules="bicRules" :label="$t('bic')" :filled="fieldsFilled"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field :value="creditorId" :label="$t('creditor-identifier')" :filled="fieldsFilled"
                    disabled></v-text-field>
                  <p v-for="paragraph in $t('membership-sepa-text')" :key="paragraph">
                    {{ paragraph }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="px-4">
                <h3 class="text-center mb-5">{{ $t("support") }}</h3>
                <p class="mb-n1">{{ $t("further-support") }}</p>
                <small>{{ $t("optional-text") }}</small>
                <v-checkbox class="mb-n6 mt-1" v-model="furtherSupport" :label="$t('support-group')"
                  value="Selbsthilfegruppe" color="accent"></v-checkbox>
                <v-checkbox class="mb-n6" v-model="furtherSupport" :label="$t('support-godparent')" value="Patensystem"
                  color="accent"></v-checkbox>
                <v-checkbox class="mb-n6" v-model="furtherSupport" :label="$t('support-events')" value="Veranstaltungen"
                  color="accent"></v-checkbox>
                <v-checkbox class="mb-n6" v-model="furtherSupport" :label="$t('support-pr')" value="Öffentlichkeitsarbeit"
                  color="accent"></v-checkbox>
                <v-checkbox class="mb-n6" v-model="furtherSupport" :label="$t('support-youth')" value="Jugendarbeit"
                  color="accent"></v-checkbox>
                <v-checkbox v-model="furtherSupport" :label="$t('support-office')" value="Büroarbeiten"
                  color="accent"></v-checkbox>
                <p class="mt-5">{{ $t("comments") }}</p>
                <v-textarea filled v-model="comments" :rules="commentRules" counter="2000" height="250"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container>
          <v-row>
            <v-col align="center">
              <v-btn text @click="step--">
                {{ $t("back") }}
              </v-btn>
            </v-col>
            <v-col align="center">
              <v-btn color="accent" @click="continueForm('form-contribution')">
                {{ $t("continue") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>
      <!-- Step 3  -->
      <v-stepper-content step="3">
        <!-- Loading -->
        <div v-if="loading">
          <v-container class="text-center">
            <v-progress-circular indeterminate size="128" class="my-16" />
          </v-container>
        </div>
        <!-- Submitted successfully -->
        <div v-else-if="submit">
          <v-container class="text-center">
            <v-row align="center">
              <v-col cols="12" sm="3" lg="2">
                <v-icon color="success" size="128">mdi-checkbox-marked-circle</v-icon>
              </v-col>
              <v-col cols="12" sm="9" lg="8">
                <h1 class="mb-5">{{ $t("membership-welcome") }}</h1>
                <p>{{ $t("membership-welcome-text") }}</p>
                <v-btn text color="accent" class="mt-3" @click="resetForms">{{
                  $t("reset")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- Consent Form -->
        <div v-else>
          <v-container class="text-center">
            <v-form ref="form-consent" v-model="validConsent">
              <h1 class="mb-5">{{ $t("membership-completion") }}</h1>
              <h3 class="text-center">{{ $t("membership-consent") }}</h3>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" lg="8" md="10">
                  <v-checkbox v-model="agreement" color="accent" :rules="agreementRules">
                    <template v-slot:label>
                      <div>
                        {{ $t("membership-agreement-basic-1") }}
                        <a target="_blank" href="../Satzung.pdf" class="link" @click.stop>{{ $t("statutes") }}</a>
                        {{ $t("membership-agreement-basic-2") }}
                        <a target="_blank" href="../Datenschutzordnung.pdf" class="link" @click.stop>{{
                          $t("privacy-policy") }}</a>
                        {{ $t("membership-agreement-basic-3") }}
                        <a target="_blank" href="../Datenschutzrechtliche_Informationen_und_Einwilligung.pdf" class="link"
                          @click.stop>{{ $t("data-protection-information") }}</a>
                        {{ $t("membership-agreement-basic-4") }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row class="mt-n5" justify="center">
                <v-col cols="12" lg="8" md="10">
                  <p>{{ $t("membership-agreement-additional") }}</p>
                  <v-checkbox v-model="agreementMail" :label="$t('membership-agreement-newsletter')"
                    color="accent"></v-checkbox>
                  <v-checkbox class="mt-n3" v-model="agreementPhone" :label="$t('memberhsip-agreement-phone')"
                    color="accent"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-if="error == true">
            <v-row justify="center">
              <v-col cols="12" lg="8" md="10">
                <v-alert type="error" class="mx-auto text-center">{{
                  $t("form-error")
                }}</v-alert>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col align="center">
                <v-btn text @click="step--">
                  {{ $t("back") }}
                </v-btn>
              </v-col>
              <v-col align="center">
                <v-btn color="accent" @click="submitForm('form-consent')">
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    // State variables
    step: 1,
    creditorId: "DE76ZZZ00002313603",
    menu: false,
    activePicker: null,
    submit: false,
    loading: false,
    invalidContributionValue: true,
    validMemberdata: false,
    validContribution: false,
    validConsent: false,
    fieldsFilled: true,
    error: false,
    // Form variables
    name: "",
    lastname: "",
    birthDate: "",
    email: "",
    phone: "",
    street: "",
    suffix: "",
    postcode: "",
    city: "",
    country: "",
    membership: "",
    contribution: "",
    furtherSupport: [],
    comments: "",
    accountHolder: "",
    iban: "",
    bic: "",
    agreement: false,
    agreementMail: false,
    agreementPhone: false
  }),
  computed: {
    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    quarterlyContribution() {
      if (this.invalidContributionValue) return "";
      var qContribution = this.contribution * 3;
      return qContribution.toFixed(2);
    },
    membershipTypes() {
      return [
        this.$t("ordinary-membership"),
        this.$t("extraordinary-membership"),
        this.$t("supporting-membership")
      ];
    },
    monthlyContributionHint() {
      if (this.membership == this.$t("ordinary-membership")) {
        return this.$t("ordinary-hint");
      }
      if (this.membership == this.$t("supporting-membership")) {
        return this.$t("supporting-hint");
      }
      return "";
    },
    nameRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("name") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", { field: this.$t("name"), count: 255 })
      ];
    },
    lastnameRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("lastname") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("lastname"),
            count: 255
          })
      ];
    },
    birthDateRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("birthdate") })
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("email") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("email"),
            count: 255
          }),
        v =>
          (v && /.+@.+\..+/.test(v)) ||
          this.$t("validation-valid", { field: this.$t("email") })
      ];
    },
    phoneRules() {
      return [
        v =>
          v.length <= 20 ||
          this.$t("validation-lenght", {
            field: this.$t("phone"),
            count: 20
          }),
        v =>
          !/[a-zA-Z]/.test(v) ||
          this.$t("validation-valid", { field: this.$t("phone") })
      ];
    },
    streetRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("street") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("street"),
            count: 255
          })
      ];
    },
    suffixRules() {
      return [
        v =>
          v.length <= 255 ||
          this.$t("validation-lenght", {
            field: this.$t("suffix"),
            count: 255
          })
      ];
    },
    postcodeRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("postcode") }),
        v =>
          (v && v.length <= 12) ||
          this.$t("validation-lenght", {
            field: this.$t("postcode"),
            count: 12
          })
      ];
    },
    cityRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("city") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("city"),
            count: 255
          })
      ];
    },
    countryRules() {
      return [
        v => !!v || this.$t("validation-req", { field: this.$t("country") }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("country"),
            count: 255
          })
      ];
    },
    membershipRules() {
      return [
        v =>
          !!v ||
          this.$t("validation-req", { field: this.$t("membership-type") })
      ];
    },
    contributionRules() {
      if (this.membership == this.$t("ordinary-membership")) {
        return [
          v =>
            !!v ||
            this.$t("validation-req", {
              field: this.$t("monthly-contribution")
            }),
          v => (v && v >= 1 && v <= 5) || this.$t("validation-contribution")
        ];
      }
      if (this.membership == this.$t("supporting-membership")) {
        return [
          v =>
            !!v ||
            this.$t("validation-req", {
              field: this.$t("monthly-contribution")
            }),
          v => (v && v >= 1) || this.$t("validation-contribution-supporting")
        ];
      }
      return [];
    },
    commentRules() {
      return [
        v =>
          v.length <= 2000 ||
          this.$t("validation-lenght", {
            field: this.$t("comment"),
            count: 2000
          })
      ];
    },
    accountHolderRules() {
      return [
        v =>
          !!v ||
          this.$t("validation-req", {
            field: this.$t("account-holder")
          }),
        v =>
          (v && v.length <= 255) ||
          this.$t("validation-lenght", {
            field: this.$t("account-holder"),
            count: 255
          })
      ];
    },
    ibanRules() {
      return [
        v =>
          !!v ||
          this.$t("validation-req", {
            field: this.$t("iban")
          }),
        v =>
          (v && v.length <= 34) ||
          this.$t("validation-lenght", {
            field: this.$t("iban"),
            count: 34
          })
      ];
    },
    bicRules() {
      return [
        v =>
          !!v ||
          this.$t("validation-req", {
            field: this.$t("bic")
          }),
        v =>
          (v && v.length <= 12) ||
          this.$t("validation-lenght", {
            field: this.$t("bic"),
            count: 12
          })
      ];
    },
    agreementRules() {
      return [v => !!v || this.$t("validation-consent")];
    }
  },
  watch: {
    // Set active picker to year when menu is true
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    // Replace comma with dot on contribution input
    contribution(val) {
      this.contribution = val.replace(",", ".");
    },
    // Clear contribution value on membership switch
    // This avoids validation reset problems
    membership() {
      this.contribution = "";
    }
  },
  methods: {
    headerColor(completed) {
      if (completed) {
        return "success";
      }
      return "accent";
    },
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    continueForm(form) {
      if (this.$refs[form].validate()) {
        this.step++;
      }
    },
    submitForm(form) {
      if (this.$refs[form].validate()) {
        this.loading = true;
        // Convert to valid displayed memberhip string to valid attribute
        let membership;
        if (this.membership == this.$t("ordinary-membership")) {
          membership = "ordentliches Mitglied";
        }
        if (this.membership == this.$t("extraordinary-membership")) {
          membership = "außerordentliches Mitglied";
        }
        if (this.membership == this.$t("supporting-membership")) {
          membership = "Fördermitglied";
        }
        // Set payload
        let payload = {
          firstname: this.name,
          lastname: this.lastname,
          birthdate: this.birthDate,
          status: membership,
          support_with: this.furtherSupport,
          notes: this.comments,
          address: this.street,
          address_suffix: this.suffix,
          postcode: this.postcode,
          city: this.city,
          country: this.country,
          email: this.email,
          phone: this.phone,
          account_owner: this.accountHolder,
          iban: this.iban,
          bic: this.bic,
          fee: this.contribution,
          consent_chat: this.agreementPhone,
          consent_newsletter: this.agreementMail
        };

        // Send request
        axios
          .post("https://api.bauchgefuehle-giessen.de/api/member", payload)
          .then(response => {
            response;
            this.loading = false;
            this.submit = true;
          })
          .catch(error => {
            error;
            this.loading = false;
            this.error = true;
          });
      }
    },
    resetForms() {
      this.name = "";
      this.lastname = "";
      this.birthDate = "";
      this.email = "";
      this.phone = "";
      this.street = "";
      this.suffix = "";
      this.postcode = "";
      this.city = "";
      this.country = "";
      this.membership = "";
      this.contribution = "";
      this.furtherSupport = [];
      this.comments = "";
      this.accountHolder = "";
      this.iban = "";
      this.bic = "";
      this.agreement = false;
      this.agreementMail = false;
      this.agreementPhone = false;
      this.step = 1;
      this.submit = false;
      this.$refs["form-memberdata"].resetValidation();
    }
  }
};
</script>
<style scoped>
.link {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>

<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col cols="12" md="6">
        <v-sheet class="pa-8 mb-8">
          <div class="text-center">
            <h1 class="mb-5">{{ $t("membership") }}</h1>
          </div>
          <div class="text-center mb-5">
            <p v-for="paragraph in $t('membership-text')" :key="paragraph">
              {{ paragraph }}
            </p>
          </div>
          <v-row>
            <v-col
              v-for="(button, index) in this.buttons"
              :key="index"
              align="center"
            >
              <v-btn color="accent" :to="button.url">
                <v-icon class="mr-1">{{ button.icon }}</v-icon>
                {{ $t(button.slug) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet class="pa-8 mb-8">
          <div class="text-center">
            <h1 class="mb-5">{{ $t("membership-types") }}</h1>
          </div>
          <div class="text-center mb-5">
            <p>{{ $t("membership-types-text") }}</p>
            <ul>
              <li
                v-for="item in $t('membership-types-list')"
                :key="item"
                class="mb-3"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <application></application>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Application from "@/components/Application.vue";

export default {
  name: "membership",
  components: {
    Breadcrumbs,
    Application
  },
  data: () => ({
    buttons: [
      {
        slug: "association",
        icon: "mdi-account-multiple-plus-outline",
        url: "/association"
      },
      {
        slug: "offerings",
        icon: "mdi-book-open-page-variant-outline",
        url: "/offerings/events"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("association"),
          disabled: false,
          href: "/association"
        },
        {
          text: this.$t("membership"),
          disabled: true
        }
      ];
    }
  }
};
</script>
